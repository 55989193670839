<template lang="pug">
div
  Selector(:bizs="items")
  ManualSend.my-10
</template>

<script>
import Selector from "./Selector";

import ManualSend from "./ManualSend";

export default {
  components: { Selector, ManualSend },
  data() {
    return { items: [] };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const params = { criteria: { member: 2 }, select: "biz name" };
      this.items = await this.$api.service.account.list(params);
    },
  },
};
</script>