<template lang="pug">
v-row(wrap, justify="center")
  simple-table.ma-5(v-if="orders")
    tbody
      tr(
        v-if="proxies",
        v-for="(item, index) in proxies",
        :key="index + 'proxy'"
      )
        td {{ item.name }}
        td {{ item.value | currency }}
        td {{ item.tip | currency }} ({{ ((item.tip / item.value) * 100) | number(1) }}%)
        td {{ item.count }}
      tr(
        v-if="values",
        v-for="(item, index) in values",
        :key="index + 'value'"
      )
        td.text-capitalize {{ item.name }}
        td {{ item.value | currency }}
        td {{ item.tip | currency }} ({{ ((item.tip / item.value) * 100) | number(1) }}%)
        td {{ item.count }}
      tr
        td Total
        td {{ totalvalue | currency }}
        td {{ totalTip | currency }}
        td {{ orders.length }}
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["orders"]),
    totalTip() {
      return this.orders.reduce((memo, item) => {
        return memo + item.payment.tip;
      }, 0);
    },
    totalvalue() {
      return this.orders.reduce((memo, item) => {
        return memo + item.payment.total;
      }, 0);
    },
    values() {
      return this.getMappedGroups("type");
    },
    proxies() {
      return this.getMappedGroups("proxy");
    },
  },
  methods: {
    getMappedGroups(name) {
      if (!this.orders) return [];
      return _.chain(this.orders)
        .groupBy(name)
        .map((list, k) => {
          return {
            name: k,
            count: list.length,
            tip: list.reduce((memo, item) => {
              return memo + item.payment.tip;
            }, 0),
            value: list.reduce((memo, item) => {
              return memo + item.payment.total;
            }, 0),
          };
        })
        .sortBy("name")
        .value();
    },
  },
};
</script>
